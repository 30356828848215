<template>
    <div class="dark:bg-gray-900 bg-white py-24 text-gray-100 ">
        <div class="lg:container mx-auto">
            <h1 class="text-4xl dark:text-white text-gray-700 mx-3">Обновление программных продуктов</h1>
        </div>
        <CategorySelector :items="categories" namespace="software"></CategorySelector>
        <List :items="itemsFiltered"></List>
        <Pagination namespace="software"></Pagination>
        <script type="module">


        </script>
    </div>

</template>

<script>

import CategorySelector from "../../components/common/CategorySelector";
import List from "../../components/software/UpdatesList";
import Pagination from "../../components/common/Pagination";
import {mapGetters} from "vuex";
import axios from "axios";


export default {
    name: "SoftwareUpdateList",
    meta:{
      title:'Обновления ПО'
    },
    data: () => ({
        items: [],
        categories: [],
        pageLocal: 1,
    }),
    computed: {
        itemsFiltered() {
            return this.items
        },
        ...mapGetters('software', ['page', 'perPage', 'category']),
        lang() {
            return this.$store.getters.lang
        }
    },
    created() {
        var splineScript = document.createElement('script')
        splineScript.id = "splinescene"
        splineScript.innerHTML = `import { Application } from './scenes/scene.json';
			const app = new Application();
			app.load('./scenes/scene.json');`
        splineScript.type = "module";
        document.body.appendChild(splineScript);
        this.fetchSoftUpdates()
    },
    mounted() {
        this.$watch((vm) => [vm.page, vm.perPage, vm.category, vm.lang], () => {
            this.fetchSoftUpdates()
        })
    },
    methods: {
        fetchSoftUpdates() {
            let payload = {
                page: this.page,
                perPage: this.perPage,
                softCategory: this.category
            }
            axios.post('https://declarant.by/rest/software/updates', payload)
                .then(res => {
                    this.items = res.data.data.items
                    this.categories = res.data.data.categories
                })
        }
    },
    components: {
        CategorySelector, List, Pagination,

    }
}
</script>

<style scoped>
canvas { width: 100%; height: 100%; outline: none; }
#container { width: 100%; height: 100%; position: relative; }
</style>