<template>
  <div class="lg:container mx-auto">
    <div class="flex justify-center dark:bg-gray-800   my-10 " :class="{'mx-3 rounded bg-white shadow-md dark:shadow-none':!dashboard, 'bg-white shadow-md':dashboard, 'p-5':hideNext}">

      <select v-model="perPageLocal" @change="setPerPage" class="dark:text-green-300 text-blue-900 dark:bg-gray-800 bg-white  focus:outline-none"
              >
        <option value="15">15</option>
        <option value="30">30</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="9999">Все</option>
      </select>


      <button v-if="page > 1" class="p-5 inline-flex items-center dark:text-green-300  text-blue-900 dark:hover:text-green-300 hover:text-blue-500"
              @click="setPage(prev)">
        <img class="mr-2 transform rotate-180 " src="@/assets/img/icons/arrow.svg" alt="">
        <span class="sm:hidden md:block">Предыдущие</span>
      </button>
      <button v-if="!hideNext"  class="p-5 items-center inline-flex dark:text-green-300  text-blue-900 dark:hover:text-green-300 hover:text-blue-500" @click="setPage(next)">
        <span class="sm:hidden md:block">Следующие</span>
        <img class="ml-2" src="@/assets/img/icons/arrow.svg" alt="Белтаможсервис">
      </button>

    </div>
  </div>
</template>

<script>

export default {
  name: "Pagination",
  data: () => ({
    perPageLocal: 15,
  }),
  props: {
    namespace: {
      type: String,
      required: true
    },
    dashboard:{
        type:Boolean
    },
    hideNext:{
        type:Boolean
    }
  },
  computed: {
    page() {
      return this.$store.getters[this.namespace + '/page']
    },
    perPage() {
      return this.$store.getters[this.namespace + '/perPage']
    },
    prev() {
      return this.page - 1
    },
    next() {
      return this.page + 1
    }
  },

  watch: {
    perPage() {
      this.perPageLocal = this.perPage
    },
  },
  methods: {
    setPage(value) {
      value > 0 ? this.$store.commit(this.namespace + '/setPage', value) : false
    },
    setPerPage() {
      this.$store.commit(this.namespace + '/setPerPage', this.perPageLocal)
    }
  }


}
</script>

<style scoped>


</style>
