<template>
  <div class="lg:container mx-auto">
    <div class="sm:block lg:flex flex-wrap ">

      <SubscribeUpdates></SubscribeUpdates>

      <div v-for="item in items" :key="item.id" class=" sm:w-full lg:w-1/3 my-3 ">
        <div class="card m-3 p-3 dark:bg-gray-800 bg-blue-100 text-gray-700 dark:text-white rounded-md h-full">
          <div class="img-box overflow-hidden">

          </div>
          <div class="text-box mb-2 relative">

            <h3 class="text-lg mt-2 mb-4 sm:max-w-xs md:max-w-full">
              {{ item.title }}
            </h3>

            <a :href="item.link" class="absolute top-0.5 right-0.5">
              <img class="download"
                   src="@/assets/img/icons/download-white.svg"
                   alt=""></a>

            <span class="text-gray-400">
            {{ item.created_at }}
            </span>
          </div>
          <router-link :to="{name:'SoftwareUpdateDetail', params:{code:item.code}}" tag="a"
                       class="inline-flex detail-link">Подробнее
            <img class="ml-2" src="@/assets/img/icons/arrow.svg" alt="">
          </router-link>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import SubscribeUpdates from "@/components/subscribe/SoftwareUpdates";

export default {
  name: "NewsList",
  components: {
    SubscribeUpdates
  },
  props: {
    items: {
      required: true,
      type: Array
    }
  }
}
</script>

<style scoped>
.download {
  height: 40px;
}

.img-box {
  max-height: 250px !important;
}

.img-box img {
  max-height: 250px !important;
}

.img-box img:hover {
  transform: scale(1.2);
}

.detail-link:hover img {
  transition: all;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
  transform: translateX(10px);
}
</style>