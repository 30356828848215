<template>
  <div class="lg:container mx-auto">
    <div class="flex flex-wrap sm:justify-between lg:justify-around dark:bg-gray-800 bg-white shadow-md dark:shadow-none mx-3 my-10 rounded-md">
      <button :class="{'current':current === '' || current === undefined}"
              class="p-5 items-center inline-flex dark:text-white text-gray-700 active hover:text-blue-500 dark:hover:text-green-300" @click="setCategory()">
        Все
      </button>
      <button :key="'category-'+item.id" v-for="item in items" :class="{'current':current === item.name}"
              class="p-5 inline-flex items-center dark:text-white text-gray-700 active hover:text-blue-500 dark:hover:text-green-300" @click="setCategory(item.name)">
        {{ item.name }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CategorySelector",
  props: {
    items: {
      required: true,
      type: Array
    },
    namespace: {
      type: String,
      required: true
    }
  },
  computed: {
    current() {
      return this.$store.getters[this.namespace + '/category']
    }
  },
  methods: {
    setCategory(value) {
      this.$store.commit(this.namespace + '/setCategory', value)
    }
  }
}
</script>

<style scoped>

.current {
  color: rgb(56, 189, 248);
}
</style>