<template>
  <div class="sm:w-full lg:w-1/3 my-3 ">
    <div class="card m-3 p-3 bg-blue-500  rounded-md h-full">
      <div class="img-box overflow-hidden">
      </div>
      <div class="text-box items-center flex flex-col ">
        <h3 class="text-lg mt-2 mb-4">
          Подписаться на обновления
        </h3>
        <div class="text-white text-center w-full" :class="{'bg-red-500':valid === false,'bg-green-400':valid === true, }"
             v-show="message.length">{{ message }}
        </div>
        <button class="bg-gray-700 w-full my-2 py-2 outline-none" id="show-modal" @click="showModal = true"
                :class="{'bg-blue-900':selected.length}">{{ title }}
        </button>
        <input class="py-2 px-2 w-full bg-blue-200 text-gray-700 focus:outline-none" type="text" placeholder="Ваш Email" v-model="email">
        <button class="mt-5 w-1/2 flex text-center justify-center items-center" @click="subscribe">
          Подписаться
          <img class="ml-5" src="@/assets/img/icons/rightarr.svg" alt="">
        </button>
      </div>

    </div>
    <transition name="modal">
      <div v-if="showModal" @close="showModal = false" class="modal-mask z-40 w-full h-full fixed top-0 left-0  table"
           @keydown.esc="showModal = false">
        <div class="modal-wrapper absolute top-1/2 left-1/2 flex">
          <div class="modal-container sm:w-80 md:w-auto  w-full sm:p-4 lg:p-14 flex flex-col bg-gray-800 rounded">

            <h1 class="text-xl mb-3">
              Выберите Программные продукты,<br> на которые Вы хотели бы подписаться
            </h1>

            <div class="flex flex-col my-5">
              <label :key="sub.title" v-for="sub in subs" class="inline-flex items-center mb-3">
                <input type="checkbox" :value="sub.value" @change="select(sub.value)" :checked="sub.selected"
                       class="form-checkbox"/>
                <span class="ml-2">{{ sub.title }}</span>
              </label>
            </div>

            <button class="modal-default-button bg-blue-400 rounded py-2" @click="showModal = false">
              OK
            </button>

          </div>
        </div>
      </div>
    </transition>
  </div>


</template>

<script>
import axios from "axios";
export default {
  name: "SoftwareUpdates",
  data: () => ({
    showModal: false,
    valid: true,
    message: '',
    email: '',
    subs: [
      {
        title: 'КПП "е-Декларант"',
        value: 2,
        selected: false
      },
      {
        title: 'АС "СЭЗ"',
        value: 3,
        selected: false
      },
      {
        title: 'АРМ "ПТО"',
        value: 4,
        selected: false
      },
      {
        title: 'АРМ "БТС: Специалист"',
        value: 5,
        selected: false
      }
    ],

  }),
  computed: {
    selected() {
      return this.subs.filter(i => i.selected !== false)
    },
    title() {
      if (!this.selected.length) {
        return 'Выберите продукт'
      }
      return 'Выбрано продуктов: ' + this.selected.length
    },
  },
  created() {
    document.addEventListener('keyup', this.close);
  },
  destroyed() {
    document.removeEventListener('keyup', this.close);
  },
  methods: {
    select(val) {
      this.subs.forEach(function (sub) {
        if (sub.value === val) {
          sub.selected = sub.selected === false;
        }
      })
    },
    subscribe() {
      let payload = {
        email: this.email,
        rubrics: this.selected.map(i => i.value)
      }
      if (payload.rubrics.length && this.isValid(payload.email) !== false) {

        axios.post('https://declarant.by/rest/subscribe/update', payload)
            .then(res => {
                this.valid = true
                this.message = res.data.data
                this.$notify({
                    title: 'Успешно',
                    text: res.data.data,
                    type: 'success'
                })
            }).catch(err => {
            this.valid = false
            this.message = err.response.data.message
            this.$notify({
                title: 'Ошибка',
                text: err.response.data.message,
                type: 'error'
            })
        })



      } else {
        this.valid = false
        this.message = 'Неверно заполнена форма'
      }
    },
    isValid(email) {
      if (email.length && email.indexOf('@') !== -1) {
        return true
      }
      return false
    },
    close(event) {
      if (event.keyCode === 27) {
        this.showModal = false
      }
    }
  }
}
</script>

<style scoped>
.modal-mask {
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  transform: translate(-50%, -50%);
}

.modal-container {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.update {
  background-color: rgb(110, 231, 183);
}
</style>